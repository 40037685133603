import React, {useEffect, useState} from 'react'
import {navigate} from 'gatsby'
import sessionManager from '../util/sessionManager'
// import socketManager from '../util/socketManager'
// import classNames from "classnames";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import * as buttonStyles from "../styles/button.module.css";
// import Card from "react-bootstrap/Card";


export default function Mentors() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


  function handleSignInClick() {
    dispatch({
      type: 'UI_LOGIN',
      data: {},
    })
  }


  if (global.current_user_vuid) {
    navigate('/mentors-access')
    return null
  }


  return (
    <main>
      <title>Mentors</title>

      <NavBar global={global} dispatch={dispatch} />
      <div className="page-heading">
        <Container>
          <h1>Mentor's Corner</h1>
        </Container>
      </div>

      <Container>
        <Row>
          <Col md={{ span: 7 }}>
            <h2>FAQs</h2>
            <h3 className="h4">What is the duration of the program?</h3>
            <p>
              CE-Oh! is a 10-week virtual business and entrepreneurship training
              program that primes participants for success. Whether a person
              wants to start their own business, enter the workforce, become an
              apprentice, or take college courses, CE-Oh! offers timeless skills
              for the modern worker.{" "}
            </p>

            <h3 className="h4">What is the role of the Team Mentor?</h3>
            <p>
              Team Mentors are asked to commit to meeting online with their
              assigned Team every other week for a maximum of 4 hours – on
              either Friday, Saturday, or Sunday. During this time, Team Mentors
              will guide their Team in the weekly activity related to building
              their simulated business and serve as the “CEO” of the simulated
              business.
            </p>

            <p>
              Team Mentors will also help reinforce participant learning,
              translate learning into a set of actionable items, guide their
              team throughout the program and ultimately, nurture development of
              the team's business plan. Although not required, Team Mentors are
              welcome to attend weekly class sessions.
            </p>

            <h3 className="h4">Why should I volunteer as a Team Mentor?</h3>
            <p>
              Participation in CE-Oh! is an excellent opportunity to expand
              networks nationally, engage with potential employees, and give
              back to your community by sharing your knowledge and experiences!
            </p>

            <h3 className="h4">
              What makes a good mentor? What kind of experience should I have?
            </h3>
            <p>
              Mentors should be well rounded entrepreneurial minded
              professionals with a desire to help others succeed. Business
              acumen is required, and mentors should be well versed in problem
              solving, social responsibility, creative thinking and ideation,
              marketing, economics, competitive analysis, small business
              finance, growth and exit planning, basic legal structures, and how
              to construct a business plan.
            </p>

            <h3 className="h4">
              Will I be trained? Do I need to go through training?
            </h3>
            <p>
              Mentors are not required to attend anything other than a formal
              orientation. They are welcome, however, to audit any portion of
              the CE-Oh! training that they would like.
            </p>
            <h3 className="h4">
              I’m sold! I’d like to be a mentor, what are my next steps?
            </h3>
            <p>
              Please contact ceoh@pcgus.com with the subject line “CE-Oh!
              Mentorship Inquiry”. One of our training coordinators will get
              back to you to set up an introduction call.
            </p>
          </Col>
          <Col md={{ offset: 1 }}>
            {/* <Card
            className='text-center'
            >
              <Card.Body>
                <p className='h5'>Already a Mentor?</p>
                <Button
                  type='button'
                  className={classNames(
                    buttonStyles.button,
                    buttonStyles.buttonPrimary,
                  )}
                  size="lg"
                  onClick={handleSignInClick}
                >
           Sign In
            </Button>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </Container>

      <div className='footer'>
        <Footer />
      </div>
    </main>
  );
}
